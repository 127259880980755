import type { Modal } from '$components/basic';
import type { ComponentProps, ComponentType, SvelteComponent } from 'svelte';
import { writable } from 'svelte/store';

interface ModalItem {
    Component: ComponentType;
    props: object;
    modalProps: ComponentProps<Modal>;
    id: string;
    resolve: (v?: any) => any;
    type: 'alert' | 'confirm' | 'prompt' | 'info' | 'custom' | 'fullcustom';
}

function createModalStore() {
    const { subscribe, update } = writable<ModalItem[]>([]);

    return {
        subscribe,
        open<T extends SvelteComponent>(
            Component: ComponentType<T>,
            props: ComponentProps<T>,
            modalProps: ComponentProps<Modal>,
            type: 'alert' | 'confirm' | 'prompt' | 'info' | 'custom' | 'fullcustom',
        ) {
            let resolve: (v?: any) => void;
            const promise = new Promise<any>(r => {
                resolve = r;
            });

            const id = `${Math.random()}`;

            update(modals => [...modals, { Component, props, modalProps, id, resolve, type }]);

            return promise;
        },
        close(id: string, v: any) {
            update(modals => {
                const modalToClose = modals.find(modal => modal.id === id);
                if (modalToClose) {
                    modalToClose.resolve(v);
                }
                return modals.filter(modal => modal.id !== id);
            });
        },
        resolveAllToFalse() {
            update(modals => {
                modals.forEach(modal => modal.resolve(false));
                return [];
            });
        },
    };
}

export const modal = createModalStore();
